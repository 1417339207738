import request from '../utils/request'
// 获取验证码
export function getCode (query) {
  return request({
    url: '/appreq/user/sendsms',
    headers: {
      isToken: false
    },
    method: 'GET',
    params: query
  })
}
// 登录接口
export function login (data) {
  return request({
    url: '/appreq/user/login',
    headers: {
      isToken: false
    },
    method: 'POST',
    data: data
  })
}
// 获取用户信息
export function getUserInfo () {
  return request({
    url: '/appreq/user/getUserInfo',
    method: 'get'
  })
}
// 获取企业信息
export function getCompanyInfo () {
  return request({
    url: '/appreq/user/getCompanyInfo',
    method: 'get'
  })
}
