<template>
  <div class="login">
    <div class="col-10 font24 font-weight margin-bottom20">请输入验证码</div>
    <div class="col-9e font16 margin-bottom60">验证码已发送到 <span class="col-10 font16 font-weight">{{phonenumber}}</span></div>
    <div class="">
      <van-password-input
        :value="code"
        :gutter="10"
        :length="6"
        :mask="false"
        :focused="showKeyboard"
        @focus="showKeyboard = true"
        @blur="showKeyboard = false"
      />
      <van-number-keyboard
        v-model="code"
        :show="showKeyboard"
        @blur="showKeyboard = false"
      />
    </div>
    <div class="col-9e font14 text-center margin-top30" v-if="time !== 0"><span class="col">{{time}}</span>秒后重新发送</div>
    <div class="font14 text-center margin-top30 col" v-if="(time === 0)&&(type === 2)" @click="getCodes">重新发送</div>
    <div class="col-9e font14 text-center margin-top30" v-if="(time === 0)&&(type === 1)">
      <div class="col-red">验证码错误</div>
      <div class="col-red">请检查验证码或  <span class="col"  @click="getCodes">重新发送</span></div>
    </div>
    <div class="font14 text-center margin-top30 col" v-if="type===0">登录中...</div>
    <div class="text-center margin-top120">
      <van-image
        width="206"
        height="206"
        :src="require('../../assets/imgs/login-code.svg')"
      />
    </div>
  </div>
</template>

<script setup name="LoginCode">
import { setToken } from '@/utils/auth'
import { getCode, login, getUserInfo } from '@/api/login'
import { useRoute, useRouter } from 'vue-router'
import { ref, watch } from 'vue'
const time = ref(60)
const type = ref(2)
var timer
const getTime = () => {
  var ss = 59
  timer = setInterval(function () {
    ss--
    if (ss <= 0) {
      clearInterval(timer)
      time.value = 0
    };
    time.value = ss
  }, 1000)
}

getTime()
const showKeyboard = ref(false)
const code = ref('')
const route = useRoute()
const router = useRouter()
const phonenumber = route.query.phonenumber
watch(code, (newVal) => {
  if (newVal.length === 6) {
    goLogin()
  }
})
const getCodes = () => {
  getCode({ phone: phonenumber }).then((res) => {
    if (res.code === 200) {
      getTime()
      type.value = 2
    }
  })
}
const goLogin = async () => {
  const params = { phonenumber: phonenumber, code: code.value }
  const result = await login(params)
  if (result != null && result.code === 200) {
    clearInterval(timer)
    type.value = 0
    time.value = 0
    setToken(result.data.token)
    getUserInfo().then((res) => {
      if (res.code === 200) {
        router.push({ path: '/index' })
      }
    })
  } else {
    clearInterval(timer)
    time.value = 0
    type.value = 1
  }
}
</script>
<style>
.login .van-password-input__security li{
  border-bottom: solid 1px #F5F5F5;
}
</style>
<style scoped>
.login{
  padding: 60px 30px 0;
  /* min-height: calc(100vh - 300px); */
  background: #fff;
}
</style>
