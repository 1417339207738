import { createRouter, createWebHashHistory } from 'vue-router'
import Login from '../views/login/index.vue'
import LoginCode from '../views/login/code.vue'
import { getToken } from '@/utils/auth'
import { Dialog } from 'vant'
const routes = [
  {
    path: '/login',
    name: 'Login',
    meta: { title: '登录', isleftarrow: true, navShow: true },
    component: Login
  },
  {
    path: '/code',
    name: 'LoginCode',
    meta: { title: '登录', isleftarrow: true, navShow: true },
    component: LoginCode
  },
  {
    path: '/',
    name: 'Index',
    meta: { tabbarShow: true, title: '首页', isleftarrow: false, navShow: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/mainpage/index.vue')
  },
  {
    path: '/news',
    name: 'News',
    meta: { tabbarShow: false, title: '新闻详情', isleftarrow: true, navShow: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/mainpage/detail.vue')
  },
  {
    path: '/person',
    name: 'Person',
    meta: { tabbarShow: false, title: '人员管理', isleftarrow: true, navShow: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/center/personnel/index.vue')
  },
  {
    path: '/persondetail',
    name: 'PersonDetail',
    meta: { tabbarShow: false, title: '人员详情', isleftarrow: true, navShow: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/center/personnel/detail.vue')
  },
  {
    path: '/contract',
    name: 'Contract',
    meta: { tabbarShow: false, title: '劳动合同', isleftarrow: true, navShow: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/center/contract/index.vue')
  },
  {
    path: '/contractdetail',
    name: 'ContractDetail',
    meta: { tabbarShow: false, title: '合同详情', isleftarrow: true, navShow: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/center/contract/detail.vue')
  },
  {
    path: '/insurance',
    name: 'Insurance',
    meta: { tabbarShow: false, title: '社保信息', isleftarrow: true, navShow: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/center/insurance/index.vue')
  },
  {
    path: '/insurancedetail',
    name: 'InsuranceDetail',
    meta: { tabbarShow: false, title: '社保详情', isleftarrow: true, navShow: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/center/insurance/detail.vue')
  },
  {
    path: '/medical',
    name: 'Medical',
    meta: { tabbarShow: false, title: '医保信息', isleftarrow: true, navShow: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/center/medical/index.vue')
  },
  {
    path: '/medicaldetail',
    name: 'MedicalDetail',
    meta: { tabbarShow: false, title: '医保详情', isleftarrow: true, navShow: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/center/medical/detail.vue')
  },
  {
    path: '/hours',
    name: 'Hours',
    meta: { tabbarShow: false, title: '工时查询', isleftarrow: true, navShow: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/center/hours/index.vue')
  },
  {
    path: '/hoursdetail',
    name: 'HoursDetail',
    meta: { tabbarShow: false, title: '工时详情', isleftarrow: true, navShow: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/center/hours/detail.vue')
  },
  {
    path: '/wages',
    name: 'Wages',
    meta: { tabbarShow: false, title: '工资查询', isleftarrow: true, navShow: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/center/wages/index.vue')
  },
  {
    path: '/wagesdetail',
    name: 'WagesDetail',
    meta: { tabbarShow: false, title: '工资详情', isleftarrow: true, navShow: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/center/wages/detail.vue')
  },
  {
    path: '/salary',
    name: 'Salary',
    meta: { tabbarShow: false, title: '工资预支', isleftarrow: true, navShow: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/center/salary/index.vue')
  },
  {
    path: '/salarydetail',
    name: 'SalaryDetail',
    meta: { tabbarShow: false, title: '预支详情', isleftarrow: true, navShow: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/center/salary/detail.vue')
  },
  {
    path: '/settlement',
    name: 'Settlement',
    meta: { tabbarShow: false, title: '结算管理', isleftarrow: true, navShow: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/center/settlement/index.vue')
  },
  {
    path: '/setdetail',
    name: 'SetDetail',
    meta: { tabbarShow: false, title: '结算详情', isleftarrow: true, navShow: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/center/settlement/detail.vue')
  },
  {
    path: '/employer',
    name: 'Employer',
    meta: { tabbarShow: false, title: '用工单位', isleftarrow: true, navShow: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/center/employer/index.vue')
  },
  {
    path: '/empdetail',
    name: 'EmpDetail',
    meta: { tabbarShow: false, title: '单位详情', isleftarrow: true, navShow: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/center/employer/detail.vue')
  },
  {
    path: '/center',
    name: 'Center',
    meta: { title: '个人中心', tabbarShow: true, navShow: true, keepAlive: true },
    component: () => import(/* webpackChunkName: "about" */ '../views/mainpage/center.vue')
  }
  // {
  //   path: '/Home',
  //   name: 'Home',
  //   component: Home
  // },
  // {
  //   path: '/about',
  //   name: 'About',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  const token = getToken()
  const whiteList = ['/login', '/code', '/', '/news']
  const n = whiteList.indexOf(to.path)
  console.log('to.path', to)
  console.log('n====', n)
  if (n < 0) {
    if (token) {
      next()
    } else {
      // Dialog.alert({
      //   title: '标题',
      //   message: '请先选择对应的入口登录哦~'
      // }).then(() => {
      //   // on close
      // })
      Dialog.confirm({
        title: '提示',
        message: '请先登录~'
      })
        .then(() => {
          router.push({ path: '/login' })
        })
        .catch(() => {
          // on cancel
        })
    }
  } else {
    next()
  }
})
export default router
