<template>
    <van-tabbar v-model="actives" :fixed="true" @change="getClass">
      <van-tabbar-item
        v-for="(item, index) in tabbars"
        :key="index"
         replace
        :to="item.name"
      >
        <span :class="currIndex == index ? actives : ''">{{ item.title }}</span>
        <template v-slot:icon="props">
          <img :src="props.active ? item.active : item.normal" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
</template>

<script>
import { Tabbar, TabbarItem } from 'vant'

export default {
  name: 'Bottomtabbar',
  computed: {
    getClosed () {
      return this.$store.state.closed
    }
  },
  watch: {
    $route (to) {
      this.getClass()
    }

  },
  data () {
    return {
      closed: false,
      currIndex: 0,
      actives: 0,
      tabbars: [
        {
          name: '/',
          title: '首页',
          normal: 'https://shuahuo-oss.oss-cn-beijing.aliyuncs.com/insurance-h5/tabbar/index.png',
          active: 'https://shuahuo-oss.oss-cn-beijing.aliyuncs.com/insurance-h5/tabbar/index-active.png'
        },
        {
          name: 'center',
          title: '我的',
          normal: 'https://shuahuo-oss.oss-cn-beijing.aliyuncs.com/insurance-h5/tabbar/mine.png',
          active: 'https://shuahuo-oss.oss-cn-beijing.aliyuncs.com/insurance-h5/tabbar/mine-active.png'
        }
      ]
    }
  },
  components: {
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem
  },
  mounted () {

  },
  methods: {
    activeTab (path) {
      var index = this.tabbars.map((item) => item.name).indexOf(path)
      if (index !== -1) {
        this.actives = index
      }
    },
    getClass () {
      const routerPath = this.$route.path
      if (routerPath === '/') {
        this.actives = 0
      } else if (routerPath === '/center') {
        this.actives = 1
      } else {
        this.actives = 0
      }
    }
  },
  created () {
    this.getClass()
  }
}
</script>

<style scoped>
.container {
  font-size: 14px;
  padding: 0;
  background: #f7f7f7;
  padding-top: 0px;
  /* min-height: 84vh; */
}
.van-tabbar-item--active {
  color: #315CEB;
}
img{
  width: 20px;
}

</style>
