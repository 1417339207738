import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import { Dialog, Toast, NavBar, Form, Field, CellGroup, Button, Image as VanImage, RadioGroup, Radio, Checkbox, CheckboxGroup, Cell, Col, Row, DatetimePicker, Popup, Picker, TreeSelect, List, PullRefresh, Icon, Divider, Lazyload } from 'vant'
import Vant from 'vant'
// 它会根据的手机尺寸来调整rem的基准值：html标签上的font-size。
import 'amfe-flexible'
import 'vant/lib/index.css'
import '@/assets/css/common.css'
import '@/assets/css/index.css'

// import TIM from 'tim-js-sdk'
// import COS from 'cos-js-sdk-v5'
// Vue.prototype.$video = Video
// import './rem.js'
// import 'postcss-pxtorem'
const app = createApp(App)
app.use(store)
  .use(router)
  .use(Vant)
  .mount('#app')
