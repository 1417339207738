<template>
  <div class="login">
    <div class="col-10 font24 font-weight margin-bottom20">欢迎使用劳务派遣系统</div>
    <div class="col-9e font16 margin-bottom60">一站式智能信息化管理</div>
    <div class="bgf">
      <van-field v-model="phone" placeholder="请输入用户名" />
    </div>
    <div class="bgf relative" style="margin-top:10px;">
      <van-field v-model="code" placeholder="请输入验证码" />
      <div class="code-btn" @click="getCodes">{{codeText}}</div>
    </div>
    <div class="submit-btn" @click="goLogin">登录</div>
  </div>
</template>

<script setup name="Login">
import { useRouter } from 'vue-router'
import { ref } from 'vue'
import { Toast } from 'vant'
import { getCode, login } from '@/api/login'
import { getToken, setToken } from '@/utils/auth'
const router = useRouter()
const token = getToken()
if (token) {
  router.push({ path: '/index' })
}

const phone = ref('')
const checkPhone = (val) => {
  let flag = true
  if (val) {
    if (!(/^1[3456789]\d{9}$/.test(val))) {
      flag = false
    }
    return flag
  }
}
var timer
const codeText = ref('获取验证码')
const code = ref('')
const getCodes = () => {
  console.log('phone', phone.value)
  var checkPhones = checkPhone(phone.value)
  if (!phone.value) {
    Toast('请输入用户名')
    return false
  } else if (!checkPhones) {
    Toast('请输入正确的的手机号码')
    return false
  };
  getCode({ phone: phone.value }).then((res) => {
    if (res.code === 200) {
      var ss = 59
      timer = setInterval(function () {
        ss--
        codeText.value = '验证码(' + ss + 's)'
        if (ss <= 0) {
          clearInterval(timer)
          codeText.value = '获取验证码'
        };
      }, 1000)
    } else {
      clearInterval(timer)
      codeText.value = '获取验证码'
    }
  })
}
const goLogin = async () => {
  var checkPhones = checkPhone(phone.value)
  if (!phone.value) {
    Toast('请输入用户名')
    return false
  } else if (!checkPhones) {
    Toast('请输入正确的的手机号码')
    return false
  };
  if (!code.value) {
    Toast('请输入验证码')
    return false
  }
  const params = { phonenumber: phone.value, code: code.value }
  const result = await login(params)
  if (result.code === 200) {
    setToken(result.data.token)
    router.push({ path: '/' })
  }
}
</script>
<style scoped>
.login{
  padding: 60px 30px;
  min-height: calc(100vh - 166px);
  background: #fff;
}
.code-btn{
  position: absolute;
  top: 20px;
  right: 0;
  height: 24px;
  font-size: 13px;
  line-height: 24px;
  padding: 0 20px;
  color: #315CEB;
  border-left: solid 2px #315CEB;
}
.submit-btn{
  height: 64px;
  line-height: 64px;
  text-align: center;
  font-size: 18px;
  color: #fff;
  border-radius: 5px;
  background: #315CEB;
  margin-top: 40px;
}
</style>
